import { UserInfo, UserInfoNullable } from '../../types';

export function getUser(
  userId: number | null,
  usersList: UserInfo[],
): UserInfoNullable {
  if (usersList.length === 0 || userId === null) {
    throw new Error('Invalid info list users');
  }

  for (const user of usersList) {
    if (user.id === userId) {
      return user;
    }
  }

  return null;
}

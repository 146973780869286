/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/naming-convention */

import axios, { AxiosError } from 'axios';
import { toCamelOrSnakeCase } from '@/shared/lib/toCamelOrSnakeCase';
import { localStorageWrapper } from '@/shared/lib/storageWrapper';
import { alertHandler } from '@/shared/lib/alertHandler';
import { userStateStore, UserStateStore } from './userState';
import { ROUTES } from '../routes';
import {
  CompanyInfo,
  CreateUser,
  RegisterData,
  StorageKey,
  UserInfo,
  UserInfoLogin,
} from '../types';
import { getUser } from '../lib/getUser';

const BASE_URL = process.env.NEXT_PUBLIC_BACK_PROD;

class UserAsync {
  state: UserStateStore;

  constructor(storeState: UserStateStore) {
    this.state = storeState;
  }

  async fetchUsersList() {
    try {
      this.state.loadings.usersInfo = true;

      const response = await axios.get<UserInfo[]>(
        `${BASE_URL}${ROUTES.UsersList}`,
        {
          params: {
            company_id: this.state.companyId,
          },
        },
      );
      const data = toCamelOrSnakeCase<UserInfo[]>(response.data, true);

      if (this.state.userInfo === null) {
        const userData = getUser(this.state.userId, data);
        this.state.userInfo = userData;
      }

      this.state.usersInfo = data;
    } catch (error) {
      alertHandler.addAlert({ alert: error });
    } finally {
      this.state.loadings.usersInfo = false;
    }
  }

  async fetchCompanyInfo() {
    try {
      this.state.loadings.companyInfo = true;

      const response = await axios.get<CompanyInfo>(
        `${BASE_URL}${ROUTES.CompanyInfo}`,
        {
          params: {
            company_id: this.state.companyId,
          },
        },
      );
      const data = toCamelOrSnakeCase<CompanyInfo>(response.data, true);

      this.state.companyInfo = data;

      if (this.state.userInfo === null) {
        this.fetchUsersList();
      }
    } catch (error) {
      alertHandler.addAlert({ alert: error });
    } finally {
      this.state.loadings.companyInfo = false;
    }
  }

  async fetchLogin(email: string, password: string) {
    try {
      this.state.loadings.login = true;

      const response = await axios.post<UserInfoLogin>(
        `${BASE_URL}${ROUTES.Login}`,
        {
          email,
          password,
        },
      );
      const data = toCamelOrSnakeCase<UserInfoLogin>(response.data, true);

      this.state.userId = data.userId;
      this.state.companyId = data.companyId;
      this.state.roleId = data.roleId;
      this.state.isVerifiedUser = true;

      localStorageWrapper.set(StorageKey._UserAuth, {
        userId: data.userId,
        companyId: data.companyId,
        roleId: data.roleId,
        isVerifiedUser: true,
      });

      return 200;
    } catch (error) {
      alertHandler.addAlert({ alert: error });
      return (error as AxiosError)?.response?.status || -1;
    } finally {
      this.state.loadings.login = false;
    }
  }

  async fetchRegister(registerData: RegisterData) {
    try {
      this.state.loadings.register = true;

      const requestData = {
        email: registerData.email.trim(),
        password: registerData.password,
        company_name: registerData.companyName.trim(),
        first_name: registerData.firstName.trim(),
        last_name: registerData.lastName.trim(),
        middle_name: registerData.middleName.trim(),
        position: registerData.position.trim(),
      };

      const response = await axios.post<UserInfoLogin>(
        `${BASE_URL}${ROUTES.Register}`,
        requestData,
      );

      const data = toCamelOrSnakeCase<UserInfoLogin>(response.data, true);

      this.state.userId = data.userId;
      this.state.companyId = data.companyId;
      this.state.roleId = data.roleId;
      this.state.isVerifiedUser = true;

      localStorageWrapper.set(StorageKey._UserAuth, {
        userId: data.userId,
        companyId: data.companyId,
        roleId: data.roleId,
        isVerifiedUser: true,
      });

      return 200;
    } catch (error) {
      alertHandler.addAlert({ alert: error });
      return (error as AxiosError)?.response?.status || -1;
    } finally {
      this.state.loadings.register = true;
    }
  }

  async createNewUser(user: CreateUser) {
    try {
      this.state.loadings.createUser = true;

      await axios.post<UserInfoLogin>(`${BASE_URL}${ROUTES.CreateUser}`, {
        first_name: user.firstName,
        last_name: user.lastName,
        middle_name: user.middleName,
        email: user.email,
        company_id: this.state.companyId,
        settings_id: this.state.companyId,
        position: user.position,
      });

      await this.fetchUsersList();
    } catch (error) {
      alertHandler.addAlert({ alert: error });
    } finally {
      this.state.loadings.createUser = false;
    }
  }
}

export const userAsyncStore = new UserAsync(userStateStore);
export type UserAsyncStore = typeof userAsyncStore;

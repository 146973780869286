export const MASKS = {
  number: /^\d+$/,
  email:
    /^[a-zA-Z0-9](?!.*[._]{2})[a-zA-Z0-9._]+@[a-zA-Z0-9-](?!.*[._]{2})[a-zA-Z0-9-]+(\.[a-zA-Z0-9-](?!.*[._]{2})[a-zA-Z0-9-]+)+$/,
  letters: /^[a-zA-Zа-яА-Я ]+$/,
  charactersNumbersSpecialSymbols:
    /^(?!.* {2})[a-zA-Zа-яА-Я0-9 _.,;!&()+=^%$\-#@~`\\-_']*$/,
};

export const MASKS_CONVERT = {
  HyphensAndUnderscoresWithSmallLetters: /([-_][a-z])/gi,
  Uppercase: /([A-Z])/g,
};

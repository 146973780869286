import { makeAutoObservable } from 'mobx';
import { localStorageWrapper } from '@/shared/lib/storageWrapper';
import {
  CompanyInfoNullable,
  StorageKey,
  UserInfo,
  UserInfoNullable,
} from '../types';

class UserState {
  loadings = {
    companyInfo: false,
    usersInfo: false,
    login: false,
    register: false,
    createUser: false,
  };

  isVerifiedUser =
    localStorageWrapper.get(StorageKey._UserAuth)?.isVerifiedUser || false;

  userId: number | null =
    localStorageWrapper.get(StorageKey._UserAuth)?.userId || null;

  companyId: number | null =
    localStorageWrapper.get(StorageKey._UserAuth)?.companyId || null;

  roleId: number | null =
    localStorageWrapper.get(StorageKey._UserAuth)?.roleId || null;

  userInfo: UserInfoNullable = null;
  usersInfo: UserInfo[] = [];
  companyInfo: CompanyInfoNullable = null;

  constructor() {
    makeAutoObservable(this);
  }
}

export const userStateStore = new UserState();
export type UserStateStore = typeof userStateStore;

import { AxiosError } from 'axios';

export function getError(error?: unknown): string | null {
  if (error && typeof error === 'object') {
    if ('isAxiosError' in error && (error as AxiosError).isAxiosError) {
      const axiosError = error as AxiosError;

      if (axiosError.response && axiosError.response.data) {
        const data = axiosError.response.data as {
          message?: string;
          detail?: string;
        };

        if (data?.message && typeof data.message === 'string') {
          return data.message;
        }
        if (data?.detail && typeof data.detail === 'string') {
          return data.detail;
        }
        return null;
      }

      return null;
    }

    if (error instanceof Error) {
      return error.message;
    }
  }

  return null;
}

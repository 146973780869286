/**
 * Типы ключей локального хранилища
 */
export enum StorageKey {
  _UserAuth = 'userAuth',
  _SettingsSearch = 'settingSearch',
}

/**
 * Данные для регистрации
 */
export type RegisterData = {
  /**
   * E-mail пользователя
   */
  email: string;
  /**
   * Пароль пользователя
   */
  password: string;
  /**
   * Название компании
   */
  companyName: string;
  /**
   * Имя пользователя
   */
  firstName: string;
  /**
   * Фамилия пользователя
   */
  lastName: string;
  /**
   * Отчество пользователя
   */
  middleName: string;
  /**
   * Позиция пользователя
   */
  position: string;
};

/**
 * Информации о текущей компании
 */
export type CompanyInfo = {
  /**
   * id компании
   */
  companyId: number;
  /**
   * имя компании
   */
  name: string;
  /**
   * Количество оставшихся запросов
   */
  requestsLeft: number;
};

/**
 * Информации о текущей компании
 */
export type CompanyInfoNullable = CompanyInfo | null;

/**
 * Информация о текущем пользователе
 */
export type UserInfoLogin = {
  /**
   * id пользователя
   */
  userId: number;
  /**
   * id компании к которой принадлежит пользователь
   */
  companyId: number;
  /**
   * id роли пользователя
   */
  roleId: number;
};

/**
 * Полная информация о пользователе
 */
export type UserInfo = {
  /**
   * id пользователя
   */
  id: number;
  /**
   * Имя пользователя
   */
  firstName: string;
  /**
   * Фамилия пользователя
   */
  lastName: string;
  /**
   * Отчество пользователя
   */
  middleName: string;
  /**
   * Email пользователя
   */
  email: string;
  /**
   * Позиция пользователя
   */
  position: string;
  /**
   * Роль пользователя
   */
  role: string;
  /**
   * Количество оставшихся запросов
   */
  requestCount: number;
  /**
   * Всего запросов
   */
  maxRequestCount: number;
};

/**
 * Информация о текущем пользователе
 */
export type UserInfoNullable = UserInfo | null;

/**
 * Информация о создании нового пользователя
 */
export type CreateUser = {
  /**
   * Имя добавляемого пользователя
   */
  firstName: string;
  /**
   * Фамилия добавляемого пользователя
   */
  lastName: string;
  /**
   * Отчество добавляемого пользователя
   */
  middleName: string;
  /**
   * Почта добавляемого пользователя
   */
  email: string;
  /**
   * Позиция добавляемого пользователя
   */
  position: string;
};

import { localStorageWrapper } from '@/shared/lib/storageWrapper';
import { userStateStore, UserStateStore } from './userState';
import { StorageKey } from '../types';

class UserSync {
  state: UserStateStore;

  constructor(storeState: UserStateStore) {
    this.state = storeState;
  }

  logout() {
    this.state.isVerifiedUser = false;
    this.state.userId = null;
    this.state.companyId = null;
    this.state.roleId = null;
    this.state.userInfo = null;
    this.state.companyInfo = null;
    this.state.usersInfo = [];

    localStorageWrapper.remove(StorageKey._UserAuth);
  }
}

export const userSyncStore = new UserSync(userStateStore);
export type UserSyncStore = typeof userSyncStore;

import { userAsyncStore, UserAsyncStore } from './models/userAsync';
import { userStateStore, UserStateStore } from './models/userState';
import { userSyncStore, UserSyncStore } from './models/userSync';

class UserStore {
  userState: UserStateStore;
  userSync: UserSyncStore;
  userAsync: UserAsyncStore;

  constructor(
    userState: UserStateStore,
    userSync: UserSyncStore,
    userAsync: UserAsyncStore,
  ) {
    this.userState = userState;
    this.userSync = userSync;
    this.userAsync = userAsync;
  }
}

export const userStore = new UserStore(
  userStateStore,
  userSyncStore,
  userAsyncStore,
);
